import { _, removeClasses } from '../utils'
import config from '../config/main'

class Menu {
    constructor() {
        this.subTrigger = _('sub-trigger', 'element').nodes()
        this.backTrigger = _('back-trigger', 'element').nodes()
        this.subNav = _('sub-nav', 'element').nodes()
        this.menuItems = _('menu-item', 'element').nodes()
        this.menuUnFocus = _('menu-unfocus', 'element-2').nodes()
        this.menuLink = _('has-subnav', 'element').nodes()
        this.islandTabs = _('island-tabs_item', 'element').nodes()
        this.islandGroups = _('island-groups_item', 'element').nodes()
        this.navLists = _('island-nav-list', 'element').nodes()

        this.navTabletBreak = window.matchMedia(
          `(max-width: 1025px)`
        )
        this.navMobileBreak = window.matchMedia(
          `(max-width: ${config.breakpoint.small}px)`
        )

        this.toggleNavFunctionality()
        this.navMobileBreak.addListener(this.toggleNavFunctionality)
        this.navTabletBreak.addListener(this.toggleNavFunctionality)

        this.subTrigger.map((el) => {
            el.addEventListener('click', this.addActiveClass)
        })
        this.backTrigger.map((el) => {
            el.addEventListener('click', this.removeActiveClass)
        })

        this.menuItems.forEach((item) => {
            item.addEventListener('mouseleave', this.handleMenuMouseLeave)
        })

        this.islandTabs.forEach((tab) => {
            tab.addEventListener('mouseenter', (event) => this.handleTabHover(event, this.classes.tabItemActive))
        })

        this.islandGroups.forEach((tab) => {
            tab.addEventListener('mouseenter', (event) => this.handleTabHover(event, this.classes.groupItemActive))
        })

        this.classes = {
            menuItemActive: 'nav__island--active',
            tabItemActive: 'island-tabs_item--active',
            groupItemActive: 'nav__groups-item--active',
            tabNavListActive: 'nav__list--visible'
        }

        // Initialize the first tab as active
        this.handlerInitializeActiveTab()
    }

    toggleNavFunctionality = () => {
        let device = 'desktop'
        const isTouch = 'ontouchstart' in document.documentElement

        if (this.navMobileBreak.matches) {
            device = 'mobile'
        }
        if (!this.navMobileBreak.matches && isTouch) {
            device = 'tablet'
        }
        switch (device) {
        case 'mobile':
            this.removeTabAccessibility()
            this.removeTapStoppers()
            break
        case 'tablet':
            this.addTapStoppers()
            break
        case 'desktop':
            this.removeTapStoppers()
            this.addTabAccessibility()
            break
        default:
            console.warn('no device detected')
        }
    }

    handlerInitializeActiveTab = () => {
        if (this.islandTabs.length) {
            this.initializeActiveTab(this.islandTabs, this.classes.tabItemActive)
        }

        if (this.islandGroups.length) {
            this.initializeActiveTab(this.islandGroups, this.classes.groupItemActive)
        }
    }

    initializeActiveTab = (elements, activeClass) => {
        const firstTab = elements[0] ?? null
        if (firstTab) {
            firstTab.classList.add(activeClass)
            this.toggleNavListVisibility(firstTab)
        }
    }

    handleTabHover = (e, activeClass) => {
        const targetTab = e.currentTarget

        // Remove active class from all tabs and navLists
        this.resetTabsAndLists()

        // Assign active class to the hovered tab
        targetTab.classList.add(activeClass)

        // Toggle the visibility of the respective nav__list
        this.toggleNavListVisibility(targetTab)
    }

    handleMenuMouseLeave = () => {
        // Reset all tabs and nav lists when mouse leaves the menu item
        this.resetTabsAndLists()
        this.handlerInitializeActiveTab()
    }

    toggleNavListVisibility = (activeTab) => {
        const activeTabId = activeTab.dataset.id

        this.navLists.forEach((navList) => {
            if (navList.dataset.targetId === activeTabId) {
                navList.classList.add(this.classes.tabNavListActive)
            } else {
                navList.classList.remove(this.classes.tabNavListActive)
            }
        })
    }

    resetTabsAndLists = () => {
        this.islandTabs.forEach((tab) => {
            tab.classList.remove(this.classes.tabItemActive)
        })
        this.islandGroups.forEach((tab) => {
            tab.classList.remove(this.classes.tabItemActive)
        })
        this.navLists.forEach((navList) => {
            navList.classList.remove(this.classes.tabNavListActive)
        })
    }

    removeTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.removeEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    addTabAccessibility = () => {
        this.menuLink.map((el) => {
            el.addEventListener('focus', this.addActiveClass)
        })
        this.menuUnFocus.map((el) => {
            el.addEventListener('focus', this.removeActiveClassAccessibility)
        })
    }

    addTapStoppers = () => {
        this.menuLink.map((el) => {
            el.addEventListener('click', this.preventFirstTap)
        })
    }

    removeTapStoppers = () => {
        this.menuLink.map((el) => {
            el.removeEventListener('click', this.preventFirstTap)
        })
    }

    preventFirstTap = (e) => {
        e.preventDefault()
        this.addTapStoppers()
        e.currentTarget.removeEventListener('click', this.preventFirstTap)
    }

    addActiveClass = (e) => {
        this.handlerInitializeActiveTab()

        e.currentTarget
            .nextElementSibling
            .classList.add(this.classes.menuItemActive)
    }

    removeActiveClass = (e) => {
        // Reset all tabs and nav lists
        this.resetTabsAndLists()

        e.currentTarget
            .closest(_('sub-nav', 'element').str())
            .classList.remove(this.classes.menuItemActive)
    }

    removeActiveClassAccessibility = () => {
        removeClasses(this.subNav, this.classes.menuItemActive)
    }
}

export default Menu