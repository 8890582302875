/*
----------------------------------------------------------------------------
Usage:
----------------------------------------------------------------------------
import Accordion from './modules/accordion'
_('accordion', 'element').nodes().map((el) => new Accordion(el))
----------------------------------------------------------------------------
Attributes:
----------------------------------------------------------------------------
data-element="accordion"           - the base element
data-behaviour="accordion-trigger" - toggles the class
data-element="accordion-target"    - has the class that gets toggled
data-id="example-id"               - unique identifier for the element to control (used only in split mode)
data-target-id="example-id"        - unique identifier for the target element to show (used only in split mode)
*/

import {_, addClasses, removeClasses} from '../utils'
export default class Accordion  {
    constructor(el) {
        this.el = el
        this.triggers = this.el.querySelectorAll(_('accordion-trigger').str())
        this.details = _('accordion-details', 'element').nodesFrom(this.el)
        this.splitContent = _('accordion-split-content', 'element').nodeFrom(this.el)

        this.triggers.forEach((trigger) => {
            trigger.addEventListener('click', this.toggleAccordion)
        })

        if (this.isSplitMode()) {
            this.handleSplitMode()
        }
    }

    toggleAccordion = (e) => {
        this.details.forEach((detail) => {
            detail.classList.remove('accordion__details--open')
        })

        e.currentTarget.parentNode.classList.toggle('accordion__details--open')

        if (this.isSplitMode()) {
            this.showDetailsInSplitContent()
        }
    }

    isSplitMode = () => {
        if (!this.splitContent) {
            return
        }

        return this.el.classList.contains('accordion--split')
    }

    handleSplitMode = () => {
        this.copyDetailsToSplitWrapper()
        this.showDetailsInSplitContent()
    }

    copyDetailsToSplitWrapper = () => {
        this.splitContent.innerHTML = ''

        this.details.forEach((detail) => {
            const copy = _('accordion-target', 'element').nodeFrom(detail).cloneNode(true)
            this.splitContent.appendChild(copy);
        });
    }

    showDetailsInSplitContent = () => {
        removeClasses(_('accordion-target', 'element').nodesFrom(this.splitContent), 'accordion__target--visible')

        this.details.forEach((detail) => {
            if (detail.classList.contains('accordion__details--open')) {
                const targetElement = this.splitContent.querySelector(`[data-target-id="${detail.dataset.id}"]`)
                if (targetElement) {
                    addClasses([targetElement], 'accordion__target--visible')
                }
            }
        });
    }
}
